.photo-album {
    display: flex;
    flex-flow: wrap;
    margin: 0;
    padding: 0;
}

.image-container {
    flex-grow: 1;
    min-width: 300px;
    position: relative;
}

.image-container:before{
    content:"";
    display: block;
    padding-top: 100%;
}

.image-container img{
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    object-fit: cover;
    object-position: center;
    border: 4px solid rgb(237, 237, 214);
}

@media screen and (max-width: 400px) {
    .image-container {
        min-width: 50%;
    }
}