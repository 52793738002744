
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  font-family: 'Aleo', serif;
}


a {
  text-decoration: none;
}